<template>
  <div class="registeredUsers">
    <div class="searchWrap">
      <span class="label"> 手机号： </span>
      <el-input
        v-model="searchValue"
        clearable
        placeholder="请输入手机号"
      ></el-input>
      <span class="label"> 状态： </span>
      <el-select v-model="selectedStatus" clearable placeholder="请选择">
        <el-option
          v-for="item in statusOptions"
          :label="item.label"
          :value="item.value"
          :key="item.value"
        />
      </el-select>
      <el-button type="primary" icon="el-icon-search" @click="search"
        >搜索</el-button
      >
    </div>
    <el-table
      :data="tableDataInfo.result"
      style="width: 100%"
      class="usersTable"
      border
    >
      <el-table-column
        v-for="c in tableColumns"
        :key="c.prop"
        :label="c.label"
        :width="c.width"
      >
        <template slot-scope="scope">
          <div v-if="c.prop.indexOf('time') > -1">
            {{ dateTransform(scope.row[c.prop]) }}
          </div>
          <div
            v-else-if="c.prop == 'status'"
            :class="[statusConfig[scope.row.status].class]"
          >
            {{ statusConfig[scope.row.status].text }}
          </div>
          <div class="operations" v-else-if="c.prop == 'operations'">
            <el-button
              size="mini"
              type="primary"
              @click="handleEdit(scope.row.id)"
              >编辑</el-button
            >
            <el-button
              size="mini"
              :type="statusConfig[scope.row.status].type"
              @click="switchStatus(scope.row)"
              >{{ statusConfig[scope.row.status].btnText }}</el-button
            >
          </div>
          <span style="margin-left: 10px" v-else>{{ scope.row[c.prop] }}</span>
        </template>
      </el-table-column>
    </el-table>
    <Pagination
      v-if="tableDataInfo.total > 8"
      :handleSizeChange="handleSizeChange"
      :handleCurrentPageChange="handleCurrentPageChange"
      :total="tableDataInfo.total"
    />
  </div>
</template>

<script>
import moment from "moment";
import Pagination from "@/components/Pagination";
export default {
  data() {
    return {
      tableColumns: [
        {
          label: "用户名称",
          prop: "cn_name",
        },
        {
          label: "注册时间",
          prop: "create_time",
        },
        {
          label: "电话号码",
          prop: "phone",
        },
        {
          label: "享用空间（M）",
          prop: "free_capacity",
        },
        {
          label: "工程数量",
          prop: "projects",
          width: "70",
        },
        {
          label: "页面数量",
          prop: "pages",
          width: "70",
        },
        {
          label: "近一次登录时间",
          prop: "last_login_time",
        },
        {
          label: "随机序列号",
          prop: "random_code",
        },
        {
          label: "状态",
          prop: "status",
        },
        {
          label: "操作",
          prop: "operations",
          width: "150",
        },
      ],
      tableDataInfo: {},
      selectedStatus: "",
      searchValue: "",
      statusOptions: [
        {
          label: "正常",
          value: "true",
        },
        {
          label: "禁用",
          value: "false",
        },
      ],
      statusConfig: {
        true: {
          text: "正常",
          btnText: "禁用",
          class: "normal",
          type: "danger",
        },
        false: {
          text: "禁用",
          btnText: "启用",
          class: "forbid",
          type: "primary",
        },
      },
    };
  },
  components: {
    Pagination,
  },
  mounted() {
    this.getTableData({});
  },
  methods: {
    handleSizeChange(v) {
      this.getTableData({ page_size: v });
    },
    handleCurrentPageChange(v) {
      this.getTableData({ page_num: v });
    },
    getTableData({ phone, status, page_size = 8, page_num = 1 }) {
      this.$request
        .queryRegisteredUsers({ page_num, page_size, phone, status })
        .then((res) => {
          if (res.status != "success") {
            this.$message.error(res.msg);
            return;
          }
          this.tableDataInfo = res.data || {};
        });
    },
    handleEdit(id) {
      this.$router.push({
        path: "/userManage/users/edit",
        query: {
          id,
        },
      });
    },
    switchStatus({ id, status }) {
      this.$request
        .updateUserStatus({ id, status: status == "true" ? "false" : "true" })
        .then((res) => {
          if (res.status != "success") {
            this.$message.error(res.msg);
            return;
          }
          this.getTableData({});
        });
    },
    search() {
      this.getTableData({
        phone: this.searchValue,
        status: this.selectedStatus,
      });
    },
    dateTransform(d) {
      return moment(d).format("YYYY-MM-DD HH:mm");
    },
  },
};
</script>

<style lang='scss' scoped>
.registeredUsers {
  padding: 24px;
  background-color: #fff;
  .searchWrap {
    display: flex;
    border-bottom: 1px solid #e6e6e6;
    padding: 16px 0;
    align-items: center;
    .label {
      margin-left: 16px;
    }
    .el-input {
      width: 200px !important;
    }
    .el-button {
      margin-left: 16px;
    }
  }
  .usersTable {
    .normal {
      color: #67c23a;
    }
    .forbid {
      color: #f56c6c;
    }
  }
}
</style>